import React, { useState, useEffect } from "react"
import classNames from "classnames"
import * as styles from "./CaseStudyBlocks.module.scss"
import { useInView } from "react-intersection-observer"

export const FadeUpOnView = ({ children }) => {
  const [show, setShow] = useState(false)
  const [ref, inView] = useInView({
    rootMargin: "5% 0px",
    triggerOnce: false,
    skip: show,
  })

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  return (
    <div
      ref={ref}
      className={classNames(styles.contentBlock, {
        [styles.inView]: inView || show,
      })}
    >
      {children}
    </div>
  )
}
const IntroBlock = ({ introBody, introResult, serviceTypes }) => {
  return (
    <div className={classNames(styles.blockContainer)}>
      <div className={classNames(styles.mediaComponentContainer)}>
        {(() => (
          <FadeUpOnView>
            <div className={styles.introContainer}>
              <div className={styles.introTextContainer}>
                <div className={styles.introBody}>{introBody}</div>
                <div className={styles.introResultsContainer}>
                  <div className={styles.introResultsHeader}>Results</div>
                  <div className={styles.introResults}>{introResult}</div>
                </div>
              </div>
              {serviceTypes && serviceTypes.length > 0 && (
                <div className={styles.serviceTypesContainer}>
                  {serviceTypes.map((serviceType, i) => (
                    <div className={styles.serviceType}>
                      {serviceType.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </FadeUpOnView>
        ))()}
      </div>
    </div>
  )
}

export default IntroBlock
