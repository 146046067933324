import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import AddSVG from "../../assets/svgs/add-black.svg"
import Slider from "react-slick"

import * as styles from "./KindWords.module.scss"

const KindWords = ({ data }) => {
  const slider = useRef(null)

  const handleImages = () => {
    slider.current.slickNext()
  }

  if (!data) return <div className="spacer"></div>

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableHeight: true,
    speed: 300,
    initialSlide: 0
  }

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.subheader}>
          Awards, recognition and love.
        </div>
        <span className={styles.header}>
          Kind words were said by kind people.
        </span>

        <button type="button" className={styles.button} onClick={handleImages}>
          <AddSVG className={styles.add} />
          <span>MORE PLEASE</span>
        </button>
      </div>

      <div className={styles.wrapper}>
        <Slider ref={slider} {...settings} className={styles.kindWordsSlider}>
          {data.map(image => {
            return (
              <div key={image.id} className={styles.imageContainer}>
                <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
              </div>
            )
          })}
        </Slider>
      </div>

      <button
        type="button"
        className={[styles.button, styles.mobile].join(" ")}
        onClick={handleImages}
      >
        <AddSVG className={styles.add} />
        <span>MORE PLEASE</span>
      </button>
    </div>
  )
}

export default KindWords
