import React from "react"
import loadable from "@loadable/component"
import { useStaticQuery, graphql } from "gatsby"

const CaseStudy = loadable(() => import('.'))

const FeaturedCaseStudy = ({ data }) => {
  const {
    contentfulCaseStudiesList: { caseStudies },
  } = useStaticQuery(graphql`
    query FeaturedCaseStudyQuery {
      contentfulCaseStudiesList(name: { eq: "Work Page Case Studies" }) {
        id
        name
        caseStudies {
          id
          name
          isLive
          slug
          noWrap
          textColor
          client {
            id
            name
          }
          serviceTypes {
            title
          }
          featuredImages {
            images {
              title
              gatsbyImageData(quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const featuredCaseStudy = React.useMemo(() => {
    return caseStudies.reduce((featured, caseStudy, i, caseStudiesList) => {
      if (caseStudy.id === data.id) {
        if (i === caseStudiesList.length - 1) {
          return caseStudiesList[0]
        } else {
          return caseStudiesList[i + 1]
        }
      }
      return featured
    }, null)
  }, [caseStudies, data.id])

  if (!featuredCaseStudy) return null

  return <CaseStudy caseStudy={featuredCaseStudy} id={featuredCaseStudy.id} />
}

export default FeaturedCaseStudy
