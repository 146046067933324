import React, { useState, useEffect } from "react"
import classNames from "classnames"
import * as styles from "./CaseStudyBlocks.module.scss"
import { useInView } from "react-intersection-observer"

import ResponsiveImage from "../responsiveImage"

export const FadeUpOnView = ({ children }) => {
  const [show, setShow] = useState(false)
  const [ref, inView] = useInView({
    rootMargin: "5% 0px",
    triggerOnce: false,
    skip: show,
  })

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  return (
    <div
      ref={ref}
      className={classNames(styles.contentBlock, {
        [styles.inView]: inView || show,
      })}
    >
      {children}
    </div>
  )
}

const TitleBlock = ({ block }) => {
  return (
    <div
      className={classNames(styles.blockContainer, {
        "hide-for-mobile": block.hideForMobile,
        "hide-for-tablet": block.hideForTablet,
        "hide-for-laptop": block.hideForDesktop || block.hideForLaptop,
        "hide-for-desktop": block.hideForDesktop,
        "hide-for-extra-large": block.hideForDesktop || block.hideForExtraLarge,
        [styles.multipleComponents]: block.mediaComponents.length > 1,
        [styles.firstBlock]: true,
      })}
    >
      {block.mediaComponents.map((component, index) => (
        <div
          key={`media-component-${index}`}
          className={classNames(styles.mediaComponentContainer, {
            [styles.fixed]: component.__typename === "ContentfulTextComponent",
          })}
        >
          {(() => (
            <FadeUpOnView>
              <ResponsiveImage
                images={component.images}
                respondToWidth={true}
                key={index}
                children={component.textOverlay}
              />
            </FadeUpOnView>
          ))()}
        </div>
      ))}
    </div>
  )
}

export default TitleBlock
