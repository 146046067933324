// extracted by mini-css-extract-plugin
export var hideForMobile = "KindWords-module--hide-for-mobile--rzor1";
export var hideForTablet = "KindWords-module--hide-for-tablet--j9G9B";
export var hideForLaptop = "KindWords-module--hide-for-laptop--B7HDP";
export var hideForDesktop = "KindWords-module--hide-for-desktop--M-SkH";
export var hideForExtraLarge = "KindWords-module--hide-for-extra-large--qU5YN";
export var showForMobileOnly = "KindWords-module--show-for-mobile-only--5tZk5";
export var showForTabletOnly = "KindWords-module--show-for-tablet-only--sutu0";
export var showForLaptopOnly = "KindWords-module--show-for-laptop-only--kMuwP";
export var showForDesktopOnly = "KindWords-module--show-for-desktop-only--UH875";
export var showForExtraLargeOnly = "KindWords-module--show-for-extra-large-only--dHrJQ";
export var fullPageContainer = "KindWords-module--full-page-container--zB7iT";
export var container = "KindWords-module--container--48dvT";
export var kindWordsSlider = "KindWords-module--kindWordsSlider--oUEXG";
export var textContainer = "KindWords-module--textContainer--8VZk-";
export var subheader = "KindWords-module--subheader--B5ptt";
export var header = "KindWords-module--header--UgoeX";
export var button = "KindWords-module--button--X+jaO";
export var mobile = "KindWords-module--mobile--zQQpE";
export var add = "KindWords-module--add--vhhwt";
export var wrapper = "KindWords-module--wrapper--vj601";
export var imageContainer = "KindWords-module--imageContainer---pgkV";