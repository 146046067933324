import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import CaseStudyBlocks from "../components/CaseStudyBlocks"
import TitleBlock from "../components/CaseStudyBlocks/TitleBlock"
import IntroBlock from "../components/CaseStudyBlocks/IntroBlock"
import KindWords from "../components/KindWords"
import FeaturedCaseStudy from "../components/caseStudy/FeaturedCaseStudy"

const CaseStudyPage = ({ data }) => {
  const {
    contentfulCaseStudy: { 
      name, 
      metaTitle, 
      metaDescription, 
      metaImage,
      client,
      hideIntroSection,
      introBody,
      introResult,
      serviceTypes,
      titleBlock,
      blocks, 
      kindWords, 
      featuredImages 
    },
  } = data

  const socialShareImage = featuredImages[0].images.sort((a, b) => {
    return (
      a.file.details.image.height / a.file.details.image.width -
      b.file.details.image.height / b.file.details.image.width
    )
  })[0]

  return (
    <FadeIn>
      <SEO
        title={
          metaTitle
            ? metaTitle
            : `${client ? client[0].name : name} | Case Study`
        }
        description={metaDescription?.metaDescription}
        socialShareImageUrl={
          metaImage ? metaImage.file.url : socialShareImage.file.url
        }
      />
      {titleBlock && 
        <TitleBlock block={titleBlock} />
      }
      {!hideIntroSection && (
        <IntroBlock
          introBody={introBody}
          introResult={introResult}
          serviceTypes={serviceTypes}
        />
      )}
      <CaseStudyBlocks blocks={blocks} />
      <KindWords data={kindWords} />
      <FeaturedCaseStudy data={data.contentfulCaseStudy} />
    </FadeIn>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCaseStudiesList(name: { eq: "Work Page Case Studies" }) {
      id
      name
      caseStudies {
        id
      }
    }
    contentfulCaseStudy(slug: { eq: $slug }) {
      id
      name
      metaTitle
      metaDescription {
        id
        metaDescription
      }
      metaImage {
        file {
          url
          details {
            size
            image {
              height
              width
            }
          }
        }
      }
      hideIntroSection
      noWrap
      introBody
      introResult
      serviceTypes {
        title
      }
      featuredImages {
        images {
          file {
            url
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
      titleBlock {
        id
        hideForDesktop
        hideForMobile
        hideForTablet
        mediaComponents {
          __typename
          ... on ContentfulLottieComponent {
            id
            name
            jsonFile {
              file {
                url
              }
            }
            loop
            renderer
            autoplay
          }
          ... on ContentfulTextComponent {
            id
            text
            fixed
          }
          ... on ContentfulVimeoComponent {
            vimeoId
            aspectRatio
            showControls
          }
          ... on ContentfulResponsiveImage {
            id
            images {
              title
              gatsbyImageData(quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
            textOverlay {
              id
              childMarkdownRemark {
                id
                html
              }
            }
          }
        }
      }
      blocks {
        id
        hideForDesktop
        hideForMobile
        hideForTablet
        mediaComponents {
          __typename
          ... on ContentfulLottieComponent {
            id
            name
            jsonFile {
              file {
                url
              }
            }
            loop
            renderer
            autoplay
          }
          ... on ContentfulTextComponent {
            id
            text
            fixed
          }
          ... on ContentfulVimeoComponent {
            vimeoId
            aspectRatio
            showControls
          }
          ... on ContentfulResponsiveImage {
            id
            images {
              title
              gatsbyImageData(quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
            textOverlay {
              id
              childMarkdownRemark {
                id
                html
              }
            }
          }
        }
      }
      kindWords {
        id
        title
        gatsbyImageData(quality: 70)
      }
    }
  }
`

export default CaseStudyPage
